define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-list-emoji", ["exports", "@popperjs/core", "virtual-dom", "discourse/widgets/raw-html", "discourse/lib/text", "discourse/widgets/widget", "@ember/runloop", "I18n"], function (_exports, _core, _virtualDom, _rawHtml, _text, _widget, _runloop, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DISPLAY_MAX_USERS = 19;
  let _popperReactionUserPanel;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-reactions-list-emoji", {
    tagName: "div.discourse-reactions-list-emoji",
    buildId: attrs => `discourse-reactions-list-emoji-${attrs.post.id}-${attrs.reaction.id}`,
    mouseOver() {
      if (!window.matchMedia("(hover: none)").matches) {
        this._setupPopper(".user-list");
        if (!this.attrs.users?.length) {
          this.callWidgetFunction("getUsers", this.attrs.reaction.id);
        }
      }
    },
    html(attrs) {
      if (attrs.reaction.count <= 0) {
        return;
      }
      const reaction = attrs.reaction;
      const users = attrs.users || [];
      const displayUsers = [(0, _virtualDom.h)("span.heading", attrs.reaction.id)];
      if (!users.length) {
        displayUsers.push((0, _virtualDom.h)("div.center", (0, _virtualDom.h)("div.spinner.small")));
      } else {
        users.slice(0, DISPLAY_MAX_USERS).forEach(user => {
          let displayName;
          if (this.siteSettings.prioritize_username_in_ux) {
            displayName = user.username;
          } else if (!user.name) {
            displayName = user.username;
          } else {
            displayName = user.name;
          }
          displayUsers.push((0, _virtualDom.h)("span.username", displayName));
        });
        if (attrs.reaction.count > DISPLAY_MAX_USERS) {
          displayUsers.push((0, _virtualDom.h)("span.other-users", _I18n.default.t("discourse_reactions.state_panel.more_users", {
            count: attrs.reaction.count - DISPLAY_MAX_USERS
          })));
        }
      }
      const elements = [new _rawHtml.default({
        html: (0, _text.emojiUnescape)(`:${reaction.id}:`, {
          skipTitle: true,
          class: this.siteSettings.discourse_reactions_desaturated_reaction_panel ? "desaturated" : ""
        })
      })];
      if (!window.matchMedia("(hover: none)").matches) {
        elements.push((0, _virtualDom.h)("div.user-list", (0, _virtualDom.h)("div.container", displayUsers)));
      }
      return elements;
    },
    _setupPopper(selector) {
      (0, _runloop.schedule)("afterRender", () => {
        const elementId = CSS.escape(this.buildId(this.attrs));
        const trigger = document.querySelector(`#${elementId}`);
        const popperElement = document.querySelector(`#${elementId} ${selector}`);
        if (popperElement) {
          _popperReactionUserPanel && _popperReactionUserPanel.destroy();
          _popperReactionUserPanel = (0, _core.createPopper)(trigger, popperElement, {
            placement: "bottom",
            modifiers: [{
              name: "offset",
              options: {
                offset: [0, -5]
              }
            }, {
              name: "preventOverflow",
              options: {
                padding: 5
              }
            }]
          });
        }
      });
    }
  });
});