define("discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction", ["exports", "discourse/lib/ajax", "discourse/models/rest", "discourse/models/topic", "discourse/models/user", "discourse/models/post", "discourse/models/category", "@ember/object"], function (_exports, _ajax, _rest, _topic, _user, _post, _category, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CustomReaction = _rest.default.extend({
    init() {
      this._super(...arguments);
      this.__type = "discourse-reactions-custom-reaction";
    }
  });
  CustomReaction.reopenClass({
    toggle(post, reactionId) {
      return (0, _ajax.ajax)(`/discourse-reactions/posts/${post.id}/custom-reactions/${reactionId}/toggle.json`, {
        type: "PUT"
      }).then(result => {
        post.appEvents.trigger("discourse-reactions:reaction-toggled", {
          post: result,
          reaction: result.current_user_reaction
        });
      });
    },
    findReactions(url, username, opts) {
      opts = opts || {};
      const data = {
        username
      };
      if (opts.beforeReactionUserId) {
        data.before_reaction_user_id = opts.beforeReactionUserId;
      }
      if (opts.beforeLikeId) {
        data.before_like_id = opts.beforeLikeId;
      }
      if (opts.includeLikes) {
        data.include_likes = opts.includeLikes;
      }
      if (opts.actingUsername) {
        data.acting_username = opts.actingUsername;
      }
      return (0, _ajax.ajax)(`/discourse-reactions/posts/${url}.json`, {
        data
      }).then(reactions => {
        return reactions.map(reaction => {
          reaction.user = _user.default.create(reaction.user);
          reaction.topic = _topic.default.create(reaction.post.topic);
          reaction.post_user = _user.default.create(reaction.post.user);
          reaction.category = _category.default.findById(reaction.post.category_id);
          const postAttrs = {
            ...reaction.post
          };
          delete postAttrs.url; // Auto-calculated by Model implementation

          reaction.post = _post.default.create(postAttrs);
          return _object.default.create(reaction);
        });
      });
    },
    findReactionUsers(postId, opts) {
      opts = opts || {};
      const data = {};
      if (opts.reactionValue) {
        data.reaction_value = opts.reactionValue;
      }
      return (0, _ajax.ajax)(`/discourse-reactions/posts/${postId}/reactions-users.json`, {
        data
      });
    }
  });
  var _default = _exports.default = CustomReaction;
});