define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-reaction-notification-item", ["discourse/widgets/widget", "discourse/widgets/default-notification-item", "discourse-common/lib/icon-library", "discourse/lib/utilities", "discourse/lib/url", "I18n"], function (_widget, _defaultNotificationItem, _iconLibrary, _utilities, _url, _I18n) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "reaction-notification-item", {
    notificationTitle() {
      return _I18n.default.t("notifications.titles.reaction");
    },
    icon(notificationName) {
      let reactionName = this.attrs.data.reaction_icon;
      let icon;
      if (reactionName) {
        icon = (0, _iconLibrary.iconNode)(reactionName);
      } else {
        icon = (0, _iconLibrary.iconNode)(`notification.${notificationName}`);
      }
      return icon;
    },
    text(_notificationName, data) {
      const count = data.count;
      const username = (0, _utilities.formatUsername)(data.display_username);
      if (data.username2) {
        const othersCount = count - 1;
        const notificationKey = othersCount === 0 ? "reaction_2" : "reaction_many";
        return _I18n.default.t(`notifications.${notificationKey}`, {
          username,
          username2: (0, _utilities.formatUsername)(data.username2),
          description: this.attrs.fancy_title,
          count: othersCount
        });
      }
      if (!count || count === 1) {
        return _I18n.default.t("notifications.reaction.single", {
          username: (0, _utilities.formatUsername)(data.display_username),
          description: this.attrs.fancy_title
        });
      } else {
        return _I18n.default.t("notifications.reaction.multiple", {
          username: (0, _utilities.formatUsername)(data.display_username),
          count
        });
      }
    },
    url() {
      const topicId = this.attrs.topic_id;
      if (topicId) {
        return (0, _utilities.postUrl)(this.attrs.slug, topicId, this.attrs.post_number);
      } else {
        const data = this.attrs.data;
        let activityName = "reactions-received";

        // All collapsed notifications were "likes"
        if (data.reaction_icon) {
          activityName = "likes-received";
        }
        return (0, _url.userPath)(`${this.currentUser.username}/notifications/${activityName}?acting_username=${data.display_username}&include_likes=true`);
      }
    }
  });
});